import {parse as parseDomain} from 'tldts';
import pageQuery from '~/graphql/queries/page.gql';
import menusQuery from '~/graphql/queries/menus/menus.gql';
import settingsQuery from '~/graphql/queries/settings.gql';
import whiteLabelQuery from '~/graphql/queries/whiteLabel.gql';

export const state = () => ({
  release: process.env.RELEASE,
  settings: [],
  page: {seo: {}, template: {}},
  menuMain: {},
  menuFooter: {},
  domain: null,
});

const getMenuItems = (menus, name) => {
  const menu = menus.find(m => m.name === name);

  if (!menu) {
    return [];
  }

  return menu.items;
};

const getLocalizedMenuName = (menuName, locale) => {
  if (locale === 'nl') {
    return menuName;
  }

  return `${menuName} (${locale})`;
};

export const actions = {
  async nuxtServerInit({dispatch, commit, getters}, {req, redirect}) {
    try {
      await dispatch('storeDispatchFunc');
      await processUrl(req, commit, this.app, getters, redirect);
    } catch (e) {
      console.error(e);
    }
  },

  async storeDispatchFunc({commit}) {
    const locale = this.$i18n.locale;
    const headerMenu = getLocalizedMenuName('hoofdmenu', locale);
    const footerMenu = getLocalizedMenuName('footermenu', locale);

    const [menus, settings] = await Promise.all([
      this.app.apolloProvider.defaultClient.query({
        query: menusQuery,
        fetchPolicy: 'no-cache',
        variables: {
          names: [headerMenu, footerMenu],
        },
      }),
      this.app.apolloProvider.defaultClient.query({
        query: settingsQuery,
      }),
    ]);

    if (menus.data) {
      commit('SET_MENU_MAIN', getMenuItems(menus.data.menus, headerMenu));
      commit('SET_MENU_FOOTER', getMenuItems(menus.data.menus, footerMenu));
    }

    commit('SET_SETTINGS', settings.data?.settings || []);
  },

  async getPage({commit}, slug = 'home') {
    const {
      data: {page},
    } = await this.app.apolloProvider.defaultClient.query({
      query: pageQuery,
      fetchPolicy: 'no-cache',
      variables: {
        segments: slug === '' ? 'home' : slug,
        locale: this.$i18n.locale,
      },
    });

    if (!page) {
      const e = new Error('Page not found');
      e.statusCode = 410;
      throw e;
    }

    commit('SET_PAGE', {
      ...page,
      ...page.template,
    });
  },
};

export const mutations = {
  SET_SETTINGS(state, payload) {
    state.settings = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_MENU_MAIN(state, payload) {
    state.menuMain = payload;
  },
  SET_MENU_FOOTER(state, payload) {
    state.menuFooter = payload;
  },
  SET_DOMAIN(state, payload) {
    state.domain = payload;
  },
};

const processUrl = async (req, commit, app, getters, redirect) => {
  const host = req.headers['x-forwarded-host'] || req.headers['x-original-host'] || req.headers.host;
  const {domain, subdomain, publicSuffix} = parseDomain(host);
  const protocol = !domain || domain.includes('localhost') || publicSuffix === 'test' ? 'http://' : 'https://';
  const port = host.includes(':') ? ':' + host.split(':')[1] : null;
  const fullDomain = protocol + domain + port;

  commit('SET_DOMAIN', fullDomain);

  if (!subdomain) {
    return;
  }

  const {data: {whiteLabel: whiteLabelResponse}} = await app.apolloProvider.defaultClient.query({
    query: whiteLabelQuery,
    variables: {
      subdomain,
    },
  });

  if (!whiteLabelResponse) {
    redirect(fullDomain);
    return;
  }

  commit('whitelabel/setWhiteLabel', whiteLabelResponse);
};
