// Better version of the routeOption method in @nuxtjs/auth that also includes checking for array content
const routeOption = (route, key, value, checkIncludes = false, returnFound = false) => {
  let found = null;

  // Loop over all the matched pages (will most likely always be only one match)
  route.matched.forEach((m) => {
    // Loop over all the components in the page
    return Object.values(m.components).forEach((component) => {
      if (process.server) {
        // Loop over the circular component structure to get the component options
        return Object.values(component._Ctor).forEach((ctor) => {
          // If the component has options (not always true), set the found value to the desired options value.
          if (ctor.options) {
            found = ctor.options[key];
          }
        });
      }
      // The same as what's happening in the above if statement
      if (component.options) {
        found = component.options[key];
      }
    });
  });

  if (found && returnFound) {
    return found;
  }

  // Check if we need to check if the array includes our desired value and check if found is an array.
  if (checkIncludes && Array.isArray(found)) {
    return found && found.includes(value);
  }

  return found === value;
};

export default ({store, route, redirect, localeRoute}) => {
  if (store.state.whitelabel.enabled && routeOption(route, 'whitelabel', false)) {
    // Double underscore is used to append locale to the route name
    if (route.name.startsWith('assortment__')) {
      redirect(store.state.domain + route.fullPath);
      return;
    }

    redirect(localeRoute({name: 'assortment-slug', params: {slug: store.state.whitelabel.product.slug}}));
  }
};
